import React from 'react'

const Meme = () => {
    return (
        <div className='min-h-[100vh] w-[100vw] bg-main py-10'>
            <h1 class="title-font text-[40px] sm:text-[80px] md:text-[80px] font-bold mb-4  text-[#fffbf5] text-center"> <span className='text-[#A8FF63]'>MEMES</span>  <span className='text-[#A8FF63]'></span></h1>
            {/* <p className='text-[#ffffff] text-center text-[20px] md:text-[30px] px-[10%] md:px-[20%]'>"With just <span className='text-[#A8FF63]'>a dollar in your pocket</span> and a dream in your heart, you hold the power to turn even the smallest beginnings into extraordinary achievements."</p> */}
            <div className='flex flex-row w-full justify-center gap-6 mt-[50px] items-center pb-8'>
                <a href='https://t.me/'>
                    <img className='w-[300px] ' src={require('../Assets/note.jpg')} />
                </a>
                
            </div>
            <div className='flex w-full justify-center mt-[50px] items-center'>
                <a href="">
                    <img className='w-[900px] min-w-[300px]' src={require('../Assets/p3.gif')} />
                </a>
            </div>
            <div className='flex w-full justify-center mt-[50px] items-center'>
                <a href="">
                    <img className='w-[900px] min-w-[300px]' src={require('../Assets/p4.gif')} />
                </a>
            </div>
            <div className='flex w-full justify-center mt-[50px] items-center'>
                <a href="">
                    <img className='w-[900px] min-w-[300px]' src={require('../Assets/p5.gif')} />
                </a>
            </div>
            
        </div>
    )
}

export default Meme