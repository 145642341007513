import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import Slider from './Components/Slider';
import Meme from './Components/Meme';

function App() {
  return (
    <div className='max-w-[100dvw] overflow-clip'>
      <Home/>
      <Slider/>
      <Meme/>
    </div>
  );
}

export default App;
